<template>
  <div>
    <loader-spinner v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="w-50 m-auto">
      <controller-date-time-settings v-if="showControllerDateTimeSettings"  :NTPSchemaDataProp="ntpSchema"  />
    </div>
    <div class="card card-block">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <WButton
            @click="openCreateModal"
          >
            {{ $t('general.add') }}
          </WButton>
          <WButton danger
            customClass="ml-1"
            @click="openMultiselectDeletingDialog"
            :disabled="!selectedServersId.length"
          >
            {{ $t('general.delete') }}
          </WButton>
        </div>
        <WButton
          @click.prevent.stop="reloadTableHandler"
        >
          {{ $t('administration.refreshNtp') }}
        </WButton>
      </div>
      <table class="table mt-1">
        <thead>
        <tr>
          <th>
            <Checkbox-component id="checkAll" :checked="isAllChecked" @click="checkAllServers"/>
          </th>
          <th>{{$t('administration.time.address')}}</th>
          <th>{{$t('administration.time.status')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr class="server-row" :key="row.id" v-for="(row, index) in servers"
            @click.prevent.stop="openEditModal(row)"
            :data-id="row.id" :data-index="index">
          <td @click.stop="checkServer" :id="`select-${row.id}`">
            <Checkbox-component
              :id="`select-${row.id}`"
              :checked="selectedServersId.includes(row.id)"

            />
          </td>
          <td>{{ row.address }}</td>
          <td> <span class="badge badge badge-info">{{ row.status }}</span></td>
        </tr>
        <tr v-if="!servers.length">
            <td colspan="6" class="no-data-row">
              <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Modal
      v-if="createNtpModalOpened"
      title="Create NTP Server"
      class="modal-primary"
      v-model="createNtpModalOpened"
      okText="Confirm"
      cancelText="Cancel"
      @ok="handleOk"
      @cancel="handleCancel"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{isModalForCreate ?
        $t('administration.createNtpServer') : $t('administration.updateNtpServer') }}</h4>
      <div class="container container-fluid">
        <div class="form-group">
          <label for="address" >{{$t('administration.time.address')}} (IPv4)</label>
          <div class="">
            <input v-validate="'required|ipv4'" data-vv-validate-on="none"
                   :data-vv-as="$t('administration.time.address')" name="address"
                   @input="removeValidationErrorByName('address')" id="address"
                   class="form-control form-control-sm"
                   type="text"
                   v-model="updatedServer.address">
            <span v-show="errors.has('address')" class="help is-danger">
              {{ errors.first('address') }}
            </span>
          </div>

        </div>
      </div>
      <div slot="modal-footer" class="modal-footer d-flex justify-content-between">
        <div>
          <WButton success outline
            v-if="isModalForCreate"
            @click="handleAdd"
          >
            {{ $t('general.add') }}
          </WButton>
          <WButton success outline
            v-if="!isModalForCreate"
            @click="handleSave"
          >
            {{ $t('general.save') }}
          </WButton>
        </div>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isModalForCreate"
            @click="handleDelete"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="handleCancel"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </Modal>
    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="closeDeleteDialogHandler.bind(this)"
      :handle-delete="deleteDialogDeleteHandler.bind(this)"
      target-text=""
    />

    <modal v-if="showdeletingServerModalForMultiselect" title="Delete Servers" v-model="showdeletingServerModalForMultiselect"
           class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('administration.time.confirmDeletingModalHeader') }}</h4>
      {{ $t('radius.confirmDeletingModal') }}
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelMultiselectDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteMultiselectedServers"
        >
          {{ $t('general.yesDelete') }}
        </WButton>
      </div>
    </modal>


  </div>
</template>

<script>
import ControllerDateTimeSettings from '@/components/ControllerDateTimeSettings.vue';
import Modal from '@/components/Modal.vue';
import DeleteDialog from '@/components/delete-dialog.vue';
import Pagination from '@/components/Pagination.vue';
import ntpServersService from '../../../services/Administration/Time/ntpServersService';
import CheckboxComponent from '@/components/Universal/Checkbox-component.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import helpers from '../../../helpers';

import { intervalUpdateModeMixin } from '@/mixins';


export default {
  name: 'Time',
  components: {
    ControllerDateTimeSettings,
    Modal,
    DeleteDialog,
    CheckboxComponent,
    SwitchComponent,
    Pagination
  },
  mixins: [intervalUpdateModeMixin],
  data() {
    return {
      showControllerDateTimeSettings: true,
      isExternal: false,
      showdeletingServerModalForMultiselect: false,
      selectedServersId: [],
      isModalForCreate: false,
      sort_by: {
        field: 'address',
        order: 1
      },
      ntpSchema: {},
      updatedNtpSchema: {},
      servers: [],
      updatedServer: {},
      newServer: {
        address: ''
      },
      tableRows: [],
      allRowsSelected: false,
      createNtpModalOpened: false,
      isDeleteOpen: false
    };
  },
  computed: {
    isShowOverPageSpinner() {
      return this.$store.state.loadingNTPServers ||
        this.$store.state.createNTPServerRequestExecuting ||
        this.$store.state.deleteNTPServerRequestExecuting ||
        this.$store.state.updateNTPServerRequestExecuting ||
        this.$store.state.updateNTPSchemaRequestExecuting;
    },
    isAllChecked() {
      let result = true;
      const radiusIdsList = helpers.combineIdsArrayFromObjectsArray(this.servers);
      if (radiusIdsList && radiusIdsList.length && this.selectedServersId && this.selectedServersId.length) {
        radiusIdsList.forEach((id) => {
          if (!this.selectedServersId.includes(id)) {
            result = false;
            return result;
          }
        });
      } else {
        result = false
        return result;
      }
      return result;
    },
  },
  methods: {
    redrawControllerDateTimeSettings(){
      this.showControllerDateTimeSettings = false;
      this.$nextTick(()=>{
        this.showControllerDateTimeSettings = true;
      })
    },

    clearUpdatedNtpSchema(){
      this.updatedNtpSchema = {}
    },
    validateAllFields(callbackFuncAfterSuccessfulValidate) {
      return this.$validator.validateAll().then(
        (result) => {
          if (result) {
            callbackFuncAfterSuccessfulValidate();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    deleteMultiselectedServers() {
      ntpServersService.deleteMultiselectedServers(this, JSON.parse(JSON.stringify(this.selectedServersId)));
    },
    cancelMultiselectDeleting() {
      this.showdeletingServerModalForMultiselect = false;
    },
    openMultiselectDeletingDialog() {
      if (!this.selectedServersId.length) {
        return;
      }
      this.showdeletingServerModalForMultiselect = true;
    },
    clearSelectedServersId() {
      this.selectedServersId = [];
    },
    checkServer(e) {
      e.stopPropagation()
      // console.log('checlradius')
      const selectedNode = e.target.closest('[data-index]');
      const { id } = selectedNode.dataset;
      if (this.selectedServersId.includes(id)) {
        this.selectedServersId.splice(this.selectedServersId.indexOf(id), 1);
      } else {
        this.selectedServersId.push(id);
      }
    },
    checkAllServers() {
      // const radiusLength = this.filteredRadius.length;
      const serversLength = this.servers.length
      const filtredSelectedId = document.querySelectorAll('.server-row');
      // console.log(radiusLength)
      if (serversLength) {
        if (!this.isAllChecked) {
          this.selectedServersId = [...filtredSelectedId].map((server) => server.dataset.id);
        } else {
          this.selectedServersId = [];
        }
      }
    },
    reloadTableHandler() {
      this.getAll();
    },
    getAll() {
      ntpServersService.getAll(this)
    },
    openCreateModal() {
      this.isModalForCreate = true;
      this.updatedServer = JSON.parse(JSON.stringify(this.newServer));
      this.openModal();
    },
    openEditModal(serverObject) {
      this.isModalForCreate = false;
      this.updatedServer = JSON.parse(JSON.stringify(serverObject));
      this.openModal()
    },
    openModal() {
      this.createNtpModalOpened = true;
    },
    closeModal() {
      this.isModalForCreate = false;
      this.createNtpModalOpened = false;
      this.clearUpdatedServer();
    },
    clearUpdatedServer(){
      this.updatedServer = [];
    },
    handleOk() {
      console.log('Ok');
    },
    handleCancel() {
      this.closeModal();
    },
    handleDelete() {
      this.isDeleteOpen = true
    },
    handleSave() {
      this.validateAllFields(this.updateServer);
    },
    handleAdd() {
      this.validateAllFields(this.createServer);
    },
    createServer() {
      ntpServersService.create(this);
    },
    updateServer() {
      ntpServersService.update(this);
    },
    closeDeleteDialogHandler() {
      this.isDeleteOpen = false;
    },
    deleteDialogDeleteHandler() {
      ntpServersService.delete(this);
      this.closeDeleteDialogHandler();
      this.closeModal();
    },
  },
  created() {
    this.getAll();
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      this.getAll();
    });
  }
};
</script>

<style scoped>

</style>
