<template>
  <div class="d-flex justify-content-between">
    <nav>
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link" href="javascript:void(0)" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
        <li class="page-item">
          <a class="page-link" href="javascript:void(0)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    <div class="d-flex align-items-center per-page-wrapper">
      <select id="per-page" class="form-control">
        <option>10</option>
      </select>
      <label for="per-page" class="mb-0 ml-1">items per page</label>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.per-page-wrapper {
  select {
    width: 100px;
  }

  label {
    width: 100px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}
</style>
