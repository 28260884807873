<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-row justify-content-between align-items-start mb-1">
      <div class="d-flex justify-content-start align-items-start">
<!--        <div class="mr-1">-->
<!--          <button-->
<!--            @click.stop.prevent="enableAllButtonClickHandler"-->
<!--            v-if="!isDisable"-->
<!--            :disabled="isDisable"-->
<!--            class="btn btn-info btn-sm"-->
<!--          >-->
<!--            {{ $t('SNMP.SNMPWirelessTraps.enableAll') }}-->
<!--          </button>-->
<!--        </div>-->
<!--        <div>-->
<!--          <button-->
<!--            @click.stop.prevent="disableAllButtonClickHandler"-->
<!--            v-if="!isDisable"-->
<!--            :disabled="isDisable"-->
<!--            class="btn btn-info btn-sm"-->
<!--          >-->
<!--            {{ $t('SNMP.SNMPWirelessTraps.disableAll') }}-->
<!--          </button>-->
<!--        </div>-->
        <div class="mr-1">
          <WButton info sm
            v-if="!isDisable"
            @click.stop.prevent="selectAllButtonClickHandler"
            :disabled="isDisable"
          >
            {{ $t('SNMP.selectAll') }}
          </WButton>
        </div>
        <div>
          <WButton info sm
            v-if="!isDisable"
            @click.stop.prevent="deselectAllButtonClickHandler"
            :disabled="isDisable"
          >
            {{ $t('SNMP.SNMPWirelessTraps.disableAll') }}
          </WButton>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-end">
        <div>
          <WButton info sm
            v-if="!isDisable && isWirelessTrapChanged"
            @click.stop.prevent="cancelWirelessTrapChanges"
            :disabled="isDisable"
          >
            {{ $t('SNMP.rollbackButton') }}
          </WButton>
        </div>
        <div>
          <WButton info sm
            customClass="ml-1"
            v-if="!isDisable"
            @click.stop.prevent="applyButtonClickHandler"
            :disabled="isDisable || !isWirelessTrapChanged"
          >
            {{ $t('SNMP.applyButton') }}
          </WButton>
        </div>
      </div>
    </div>
    <div class="d-flex w-100 flex-row justify-content-between align-items-start mb-1">
      <div class="d-flex w-100 flex-row justify-content-between flex-wrap align-items-start mb-1">
        <div
          v-for="columnBlocks of [leftColumnBlocksNames, rightColumnBlocksNames]"
          class="d-flex flex-column snmp-wireless-traps-blocks__column"
        >
          <SNMPWirelessTrapsBlock
            v-for="blockName of columnBlocks.filter((item)=> isShowWirelessTrapBlock(item))"
            :key="blockName"
            @optionChanged="optionChangedHandler"
            @enabledChanged="enabledChangedHandler"
            :name="blockName"
            :options-prop="wirelessTraps[blockName]"
            :enabled-prop="true"
            class="mb-1"
            :is-show-option-in-wireless-trap-block-function="isShowOptionInWirelessTrapBlock"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SNMPWirelessTrapsBlock from '../../../../components/Administration/SNMP/SNMPWirelessTrapsBlock.vue';
import snmpGeneralService from '../../../../services/Administration/SNMP/snmpGeneralService';


// const LEFT_COLUMN_BLOCKS_NAMES = ['mesh_trap', 'rf_trap', 'wireless_mobility_trap', 'rrm_trap', 'general_controller'];
// const RIGHT_COLUMN_BLOCKS_NAMES = ['wireless_client_trap', 'roque_trap', 'ap_trap'];

const LEFT_COLUMN_BLOCKS_NAMES = ['ap_trap', 'mesh_trap', 'wireless_mobility_trap', 'rrm_trap'];
const RIGHT_COLUMN_BLOCKS_NAMES = ['wireless_client_trap', 'roque_trap', 'rf_trap', 'general_controller'];


export default {
  name: 'SNMPWirelessTraps',
  components: { SNMPWirelessTrapsBlock },
  data() {
    return {
      generalSNMPSettigns: [],
      snmpWirelessTraps: [],
      wirelessTraps: {
      },
      wirelessTrapsForCompare: {},
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    isWirelessTrapChanged(){
      const wirelssTraps = JSON.parse(JSON.stringify(this.wirelessTraps));
      const wirelessTrapsForCompare = JSON.parse(JSON.stringify(this.wirelessTrapsForCompare));
      return JSON.stringify(wirelssTraps) !== JSON.stringify(wirelessTrapsForCompare);
    },
    allBlockNames() {
      return [...this.leftColumnBlocksNames, ...this.rightColumnBlocksNames];
    },
    leftColumnBlocksNames() {
      return LEFT_COLUMN_BLOCKS_NAMES;
    },
    rightColumnBlocksNames() {
      return RIGHT_COLUMN_BLOCKS_NAMES;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPGeneralForWirelessTrapsRequestExecuting ||
        this.$store.state.updateSNMPGeneralForWirelessTrapsRequestExecuting
    }
  },
  methods: {
    isShowOptionInWirelessTrapBlock(blockName, optionName) {
      if (this.showAllSettingsOnSNMPPartitionPages) {
        return true;
      }
      if (blockName === 'wireless_client_trap') {
        if (['associate', 'disassociate'].includes(optionName)) {
          return true;
        }

      }
      if (blockName === 'ap_trap') {
        if (['interface_up', 'mode', 'ap_stats'].includes(optionName)) {
          return true;
        }
      }
      return false;
    },
    isShowWirelessTrapBlock(blockName) {
      if (this.showAllSettingsOnSNMPPartitionPages){
        return true;
      }
      if (['wireless_client_trap', 'ap_trap'].includes(blockName)) {
        return true;
      }
      return false;
    },
    disableAllEnableAllCheckbox() {
      try {
        const checkboxesList = document.querySelectorAll("[id*='enable-all-checkbox']");
        // console.log(checkboxesList)
        if (checkboxesList && checkboxesList.length) {
          for (let i = 0; i < checkboxesList.length; i++) {
            checkboxesList[i].checked = false;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    cancelWirelessTrapChanges() {
      this.wirelessTraps = JSON.parse(JSON.stringify(this.wirelessTrapsForCompare))
      this.disableAllEnableAllCheckbox()
    },
    enableAllButtonClickHandler() {
      // console.log('enableAll Button Click');
      this.allBlockNames.forEach((blockName) => {
        this.wirelessTraps[blockName]['enabled'] = true;
      });
    },
    disableAllButtonClickHandler() {
      // console.log('disableAll Button Click');
      this.allBlockNames.forEach((blockName) => {
        this.wirelessTraps[blockName]['enabled'] = false;
      });
    },
    // selectAllButtonClickHandler() {
    //   Object.keys(this.wirelessTraps).forEach((trapBlockName) => {
    //     Object.keys(this.wirelessTraps[trapBlockName]).forEach((wirelessTrapName) => {
    //       this.wirelessTraps[trapBlockName][wirelessTrapName] = true;
    //     });
    //   });
    // },
    // deselectAllButtonClickHandler() {
    //   Object.keys(this.wirelessTraps).forEach((trapBlockName) => {
    //     Object.keys(this.wirelessTraps[trapBlockName]).forEach((wirelessTrapName) => {
    //       this.wirelessTraps[trapBlockName][wirelessTrapName] = false;
    //     });
    //   });
    //   this.disableAllEnableAllCheckbox();
    // },
    selectAllButtonClickHandler() {
      Object.keys(this.wirelessTraps).forEach((trapBlockName) => {
        Object.keys(this.wirelessTraps[trapBlockName]).forEach((wirelessTrapName) => {
          if (this.isShowOptionInWirelessTrapBlock(trapBlockName, wirelessTrapName)) {
            this.wirelessTraps[trapBlockName][wirelessTrapName] = true;
          }
        });
      });
    },
    deselectAllButtonClickHandler() {
      Object.keys(this.wirelessTraps).forEach((trapBlockName) => {
        Object.keys(this.wirelessTraps[trapBlockName]).forEach((wirelessTrapName) => {
          if (this.isShowOptionInWirelessTrapBlock(trapBlockName, wirelessTrapName)) {
            this.wirelessTraps[trapBlockName][wirelessTrapName] = false;
          }
        });
      });
      this.disableAllEnableAllCheckbox();
    },
    applyButtonClickHandler() {
      this.disableAllEnableAllCheckbox();
      this.generalSNMPSettigns.snmp_wireless_traps = JSON.parse(JSON.stringify(this.wirelessTraps))
      snmpGeneralService.updateForWirelessTraps(this)
    },
    optionChangedHandler(payload) {
      // console.log(payload);
      // this.wirelessTraps[payload.name]['options'] = payload.options;
      // console.log(payload)
      this.wirelessTraps[payload.name] = payload.options;
    },
    enabledChangedHandler(payload) {
      this.wirelessTraps[payload.name]['enabled'] = payload.enabled;
    },
    getAll() {
      // snmpWirelessTrapsService.getAll(this);
      snmpGeneralService.getAllForWirelessTraps(this);
    }
  },
  created() {
    this.getAll()
  },
  // watch: {
  //   generalSNMPSettigns: {
  //     deep: true,
  //     handler(){
  //       this.wirelessTraps = this.generalSNMPSettigns.snmp_wireless_traps
  //     }
  //   }
  // }
};
</script>

<style scoped>
@media (min-width: 768px) {
  .snmp-wireless-traps-blocks__column {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .snmp-wireless-traps-blocks__column {
    width: 49%;
  }
}

@media (min-width: 1200px) {
  .snmp-wireless-traps-blocks__column {
    width: 49%;
  }
}
</style>
