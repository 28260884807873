import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import { API_URL, GAPI_URL } from '@/config';

export default {
  getAll(ctx) {
    ctx.hosts = [];
    ctx.ntpSchema = {};
    const params = {
      action: 'R',
      location: ctx.$store.getters.getDefaultLocationForRequests(ctx),
      with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx)
    };

    if (ctx.filters?.location) {
      if (ctx.filters.location === 'All locations') {
        params.location = ctx.$store.state.requestBaseUserLocationIfNoSelected ? ctx.userBaseLocation : undefined;
        params.with_childs = true;
      } else {
        params.location = ctx.filters.location;
        params.with_childs = ctx.filters.with_childs;
      }
    }

    if (ctx.sort_by !== undefined && typeof ctx.sort_by === 'object' && Object.keys(ctx.sort_by) !== 0) {
      params.sort_by = JSON.parse(JSON.stringify(ctx.sort_by));
    }

    ctx.$store.commit('loadingNTPServersBegin');
    Vue.axios.post(`${API_URL || ''}/api/ntp_management/ntp_schema `, params).then(
      (response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.data.itemslist);
          let serversListFromResponse = [];
          let ntpSchemaFromResponse = {};
          if (
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length &&
            typeof response.data.data.itemslist[0] === 'object' &&
            response.data.data.itemslist[0] !== null
          ) {
            ntpSchemaFromResponse = response.data.data.itemslist[0];
            if (
              Object.prototype.hasOwnProperty.call(response.data.data.itemslist[0], 'ntp_servers') &&
              Array.isArray(response.data.data.itemslist[0].ntp_servers)
            ) {
              serversListFromResponse = JSON.parse(JSON.stringify(response.data.data.itemslist[0].ntp_servers));
            }
          }
          ctx.servers = serversListFromResponse;
          ctx.ntpSchema = ntpSchemaFromResponse;
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.$store.commit('loadingNTPServersEnd');
      },
      (err) => {
        ctx.$store.commit('loadingNTPServersEnd');
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
      }
    );
  },
  create(ctx) {
    ctx.$store.commit('createNTPServerReqBegin');
    const created = JSON.parse(JSON.stringify(ctx.updatedServer));

    if (typeof created.port === 'number') {
      created.port = created.port.toString();
    }
    if (typeof created.priority === 'number') {
      created.priority = created.priority.toString();
    }

    const createQuery = {
      action: 'C',
      items: { 0: { ...created } }
    };
    Vue.axios.post(`${API_URL || ''}/api/ntp_management/ntp_server`, createQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('administration.time.notificationCreatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('createNTPServerReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('createNTPServerReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  updateNtpSchema(ctx) {
    ctx.$store.commit('updateNTPSchemaReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedNtpSchema));
    const updatedgId = 0;
    const updatedINBackFormat = {
      ntp_schema: {
        active: updated.active,
        external_ntp: updated.external_ntp,
        ntp_port: updated.ntp_port
      }
    };

    const updateQuery = {
      action: 'U',
      items: { [updatedgId]: updatedINBackFormat }
    };

    Vue.axios.post(`${API_URL || ''}/api/ntp_management/ntp_schema`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('administration.time.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
            ctx.redrawControllerDateTimeSettings();
          } else {
            ctx.getAll();
            ctx.redrawControllerDateTimeSettings();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.clearUpdatedNtpSchema();
        ctx.closeExternalOrNotSelectModal();
        ctx.$store.commit('updateNTPSchemaReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateNTPSchemaReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  update(ctx) {
    ctx.$store.commit('updateNTPServerReqBegin');
    const updated = JSON.parse(JSON.stringify(ctx.updatedServer));
    const updatedgId = updated.id;

    if (typeof updated.port === 'number') {
      updated.port = updated.port.toString();
    }
    if (typeof updated.priority === 'number') {
      updated.priority = updated.priority.toString();
    }

    const updateQuery = {
      action: 'U',
      items: { [updatedgId]: updated }
    };

    Vue.axios.post(`${API_URL || ''}/api/ntp_management/ntp_server`, updateQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          VueNotifications.success({ message: ctx.$t('administration.time.notificationUpdatedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('updateNTPServerReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('updateNTPServerReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },

  delete(ctx) {
    ctx.$store.commit('deleteNTPServerReqBegin');
    const serverForDelete = JSON.parse(JSON.stringify(ctx.updatedServer));

    const deleteQuery = {
      id: serverForDelete.id
    };
    Vue.axios.post(`${GAPI_URL}/api/v1/ntp/delete_server`, deleteQuery).then(
      (response) => {
        if (response.data.status === 'success' || response.status === 200) {
          VueNotifications.success({ message: ctx.$t('administration.time.notificationDeletedSuccessfully') });
          // ctx.getAllDitExpots(ctx);
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'data') &&
            typeof response.data.data === 'object' &&
            Object.prototype.hasOwnProperty.call(response.data.data, 'itemslist') &&
            Array.isArray(response.data.data.itemslist) &&
            response.data.data.itemslist.length > 0
          ) {
            ctx.getAll();
          } else {
            ctx.getAll();
          }
        } else if (response.data.status === 'error') {
          VueNotifications.error({ message: response.data.description });
        }
        ctx.closeModal();
        ctx.$store.commit('deleteNTPServerReqEnd');
      },
      (err) => {
        // VueNotifications.error({ message: err });
        ctx.$store.commit('deleteNTPServerReqEnd');

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }
        ctx.error = err;
      }
    );
  },
  deleteMultiselectedServers(ctx, idsList) {
    if (!idsList.length) {
      return;
    }
    const serversForDelete = idsList.reduce((serversList, serverId) => {
      return [...serversList, { id: serverId }];
    }, []);
    const deleteQuery = { servers: serversForDelete };

    ctx.$store.commit('deleteNTPServerReqBegin', true);
    Vue.axios.post(`${GAPI_URL}/api/v1/ntp/delete_servers`, deleteQuery).then(
      (response) => {
        if (response.data.status === 'success' || response.status === 200) {
          ctx.showdeletingServerModalForMultiselect = false;
          ctx.clearSelectedServersId();
          VueNotifications.success({message: ctx.$t('administration.time.notificationDeletedSuccessfully')});
          ctx.getAll();
        } else if (response.data.status === 'error') {
          VueNotifications.error({message: response.data.description});
        }
        // ctx.deletingRadiusModal = false;
        // ctx.$store.state.editRadiusIndex = '';
        ctx.$store.commit('deleteNTPServerReqEnd', false);
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({message: err});
        }

        ctx.$store.commit('deleteNTPServerReqEnd', false);
        ctx.error = err;
      }
    );
  }
};
