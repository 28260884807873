<template>
  <div class="w-100" style="position: relative">
    <loader-spinner-small v-if="showOverAllSpinner"></loader-spinner-small>
    <div class="card card-block">
      <div class="d-flex flex-column justify-content-between align-items-center">
        <div class="w-100 mb-1">
          <div class="d-flex flex-row justify-content-between">
            <div>
              <div v-if="externalOrNotNTPForDisplayInUI">
                {{ $t('controllerDateTimeSettings.source') }}:&nbsp;{{
                externalOrNotNTPForDisplayInUI }}
              </div>
            </div>
            <div>
              {{ currentDateForShowInUI }}
            </div>
          </div>
        </div>
        <div class="w-100 mb-1 time-and-day-of-week-wrapper">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="day-of-week-wrapper d-flex justify-content-between w-75">
              <div
                :key="dayNumber"
                class="d-flex justify-content-center align-items-cente day-of-week"
                :class="{ 'active-day-of-week': dayNumber === currentDayOfWeekNumber }"
                v-for="dayNumber in daysOfWeekOrder"
              >
                <div>
                  {{ daysOfWeek[dayNumber] }}
                </div>
              </div>
            </div>
            <div class="time-showing-wrapper">
              <div v-if="currentTimeForDisplayInUI">
                {{ currentTimeForDisplayInUI }}
              </div>
              <div v-else>
                <!--                {{ $t('general.noData') }}-->
                ___ : ___ : ___
              </div>
            </div>
            <div class="w-100 d-flex flex-row justify-content-between time-zone-name-wrapper">
              <div class="w-25">
               <small class="small">{{$t('controllerDateTimeSettings.dateTimeDisplayAsOnControllerHint')}}</small>
              </div>
              <div
                class="time-zone-name"
                v-tooltip.top-center="{
                  content: currentTimezoneForShowInUI,
                  popperOptions: {
                    modifiers: {
                      preventOverflow: {
                        boundariesElement: 'window'
                      }
                    }
                  }
                }"
              >
                {{ currentTimezoneForShowInUI }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex flex-row justify-content-between">
            <div  @click.stop.prevent="syncDateAndTimeClickHandler">
              <span v-if="!isDisable">
                <a href="#" class="ml-q mb-1">{{ $t('controllerDateTimeSettings.syncDateAndTime') }}</a>
              </span>
            </div>
            <div></div>
            <div  v-if="showRefreshClickButton">
              <div v-if="showRefreshClickButton" @click.stop.prevent="refreshClickHandler">
                <span>
                  <a href="#" class="ml-q mb-1">{{ $t('controllerDateTimeSettings.refresh') }}</a>
                </span>
              </div>
            </div>
            <div v-if="!isDisable" @click.stop.prevent="changeDateAndTimeClickHandler">
              <span>
                <a href="#" class="ml-q mb-1">{{ $t('controllerDateTimeSettings.changeDateAndTime') }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      v-if="isModalOpen"
      :scrollBarWidthEqualPaddingRightForBody="false"
      :backdrop="false"
      :title="this.$t('controllerDateTimeSettings.changeDateAndTime')"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <loader-spinner v-if="showOverModalSpinner"></loader-spinner>
      <h4 slot="modal-title" class="modal-title">
        {{ this.$t('controllerDateTimeSettings.changeDateAndTime') }}
      </h4>

      <div>
        <div class="form-group" v-if="showDatePickerInModal">
          <label for="tz">{{ $t('controllerDateTimeSettings.dateAndTime') }}:</label>
          <div class="w-100">
            <date-picker
              @open="datePickerIsOpened = true"
              @close="datePickerIsOpened = false"
              :append-to-body="true"
              :disabled="isDisable"
              @input="datePickerSelectHandler"
              :format="'HH:mm:ss, D MMM YYYY'"
              :show-icon="false"
              :range="false"
              v-model="datePickerTsSelected"
            />
          </div>
        </div>
        <div class="form-group d-flex">
          <div class="w-100 d-flex align-items-start">
          <div class="mr-1 w-25">
            <WInput
              md
              :label="$t('controllerDateTimeSettings.timezoneAcronym')"
              :data-vv-as="$t('controllerDateTimeSettings.timezoneAcronym')"
              :errorText="errors.first('timezoneAcronym')"
              name="timezoneAcronym"
              id="timezoneAcronym"
              :disabled="isDisable"
              data-vv-validate-on="none"
              @input="inputInModalInputsHandler('timezoneAcronym')"
              v-validate="'required|max:5'"
              :placeholder="''"
              v-model="timezoneAcronymForModal"
            />
          </div>
          <div class="w-75">
            <div class="w-100 time-zone-multiselect-wrapper">
              <multiselect
                id="tz"
                v-model="timezoneSelectedInMultiselect"
                :options="timeZonesOptions"
                :disabled="isDisable"
                @select="
              (selectedOpt) => {
                this.fillFieldsInModalWhenSelectTimezoneOption(selectedOpt)
              }
            "
                class="input"
                :class="{ 'is-danger': false }"
                :data-vv-as="$t('marketing.workTimeTimezone')"
                label="text"
                track-by="text"
                :empty="true"
                :allow-emty="true"
                :placeholder="$t('marketing.workTimeTimezoneMultiselectPlaceholder')"
                :selectLabel="$t('general.multiSelectPressToSelect')"
                :deselectLabel="$t('general.multiSelectDeselectLabel')"
                :selectedLabel="$t('general.multiSelectSelected')"
              >
                <template v-slot:noOptions>
                  {{ $t('general.notFound') }}
                </template>
                <template v-slot:noResult>
                  {{ $t('general.notFound') }}
                </template>
                <template slot="singleLabel" slot-scope="props"><span
                  class="option__title">{{ cutOffLongTextForTimezoneMultiSelect(props.option.text) }}</span></template>
              </multiselect>
            </div>
          </div>
            </div>
        </div>
        <div>
          <label>{{ $t('controllerDateTimeSettings.offset') }}:</label>
        </div>
        <div class="form-group d-flex flex-column w-100">
          <div class="w-50 d-flex">
            <div class="form-group w-50 mr-h">
              <WInput
                md
                :label="$t('controllerDateTimeSettings.offsetHours')"
                :data-vv-as="$t('controllerDateTimeSettings.offsetHours')"
                :errorText="errors.first('offsetHours')"
                type="number"
                name="offsetHours"
                id="offsetHours"
                :disabled="isDisable"
                data-vv-validate-on="none"
                @input="inputInModalInputsHandler('offsetHours')"
                v-validate="'required|integer|between:-12,13'"
                :placeholder="''"
                v-model.number="offsetHoursForModal"
              />
            </div>
            <div class="form-group w-50">
              <WInput
                md
                :label="$t('controllerDateTimeSettings.offsetMinutes')"
                :data-vv-as="$t('controllerDateTimeSettings.offsetMinutes')"
                 :errorText="errorTextForOffsetMinutesField"
                type="number"
                name="offsetMinutes"
                id="offsetMinutes"
                :disabled="isDisable"
                data-vv-validate-on="none"
                @input="inputInModalInputsHandler('offsetMinutes')"
                v-validate="'required|numeric|included:0,30,45'"
                :placeholder="''"
                v-model.number="offsetMinutesForModal"
              />

            </div>
          </div>
        </div>

      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton secondary outline
          @click="modalCloseButtonHandler"
          :disabled="showOverModalSpinner"
        >
          {{ $t('general.close') }}
        </WButton>
        <WButton success outline
          v-if="!isDisable"
          customClass="mr-1"
          :disabled="errors.any() || showOverModalSpinner"
          @click="modalSaveButtonHandler"
        >
          {{ $t('general.save') }}
        </WButton>
      </div>
    </modal>

    <modal
      v-if="isSyncModalOpen"
      :scrollBarWidthEqualPaddingRightForBody="false"
      :backdrop="false"
      :title="this.$t('controllerDateTimeSettings.syncDateAndTime')"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isSyncModalOpen"
    >
      <loader-spinner v-if="showOverModalSpinner"></loader-spinner>
      <h4 slot="modal-title" class="modal-title">
        {{ this.$t('controllerDateTimeSettings.syncDateAndTime') }}
      </h4>

      <div>
        <p>{{ this.$t('controllerDateTimeSettings.syncDateAndTimeConfirm') }}</p>

      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton secondary outline
          @click="syncModalCloseButtonHandler"
          :disabled="showOverModalSpinner"
        >
          {{ $t('general.close') }}
        </WButton>
         <WButton success outline
            v-if="!isDisable"
            customClass="mr-1"
            @click="syncModalSaveButtonHandler"
            :disabled="showOverModalSpinner"
          >
            {{ $t('general.yes') }}
          </WButton>
      </div>
    </modal>

  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import { Multiselect } from 'vue-multiselect';
import VueNotifications from 'vue-notifications';
import { API_URL } from '@/config';
import DatePicker from './date-picker.vue';
import Modal from './Modal.vue';
// import timeZones from '@/timeZones';
import { timeZonesListMixin } from '@/mixins';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

const DAYS_OF_WEEK = {
  ru: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
};

const DAYS_OF_WEEK_ORDER = [1, 2, 3, 4, 5, 6, 0];

export default {
  name: 'ControllerDateTimeSettings',
  components: { Modal, Multiselect, DatePicker },
  mixins: [timeZonesListMixin],
  data() {
    return {
      offsetMinutesForModal: 0,
      offsetHoursForModal: 0,
      offsetMinutesFromBackend: 0,
      offsetHoursFromBackend: 0,
      timezoneAcronymFromBackend: '',
      timezoneAcronymForModal: '',
      systemTimeUnixFromBackend: undefined,
      showDatePickerInModal: true,
      secondsPlusInterval: undefined,
      showGetCurrentContollerTimeSpinner: false,
      showGetNTPSchemaDataSpinner: false,
      showOverModalSpinner: false,
      showRefreshClickButton: false,
      isModalOpen: false,
      isSyncModalOpen: false,
      timezoneSelectedInMultiselect: null,
      datePickerTsSelected: {},
      datePickerTsSelectedWithOffsetsForSendToBack: undefined,
      datePickerIsOpened: false,
      NTPSchemaData: {}
    };
  },
  props: {
    NTPSchemaDataProp: {
      type: Object,
      required: true,
      default() {
        return {};
      }

    }
  },
  inject: ['$validator'],
  watch: {
    offsetMinutesForModal() {
      this.setDatePickerTsSelectedWithOffsetsForSendToAPI(this.datePickerTsSelected);
    },
    offsetHoursForModal() {
      this.setDatePickerTsSelectedWithOffsetsForSendToAPI(this.datePickerTsSelected);
    },
    NTPSchemaDataProp: {
      handler() {
        this.NTPSchemaData = { ...this.NTPSchemaDataProp }
      },
      deep: true
    }
  },
  methods: {
    inputInModalInputsHandler(fieldName) {
      this.removeValidationErrorByName(fieldName);
      this.clearTimeZoneSelector();
    },
    clearTimeZoneSelector() {
      this.timezoneSelectedInMultiselect = null;
    },
    /**
     * Обрезает слишком длиннй текст с названимем таймзоны для селектора таймзон
     * @returns {string}
     */
    cutOffLongTextForTimezoneMultiSelect(text) {
      try {
        if (text.length > 50) {
          return `${text.slice(0, 50)}...`;
        }
        return text;
      } catch (e) {
        return text;
      }
    },
    /**
     * Наполняет поля в модалке, после того как была выбрана таймзона в селекторе
     * @param {object} объект таймзоны из списка с таймзонами timeZonesOptions
     */
    fillFieldsInModalWhenSelectTimezoneOption(selectedOpt) {
      // console.log('selectedOpt', selectedOpt)
      this.timezoneAcronymForModal = selectedOpt.abbr;
      this.offsetHoursForModal = Math.trunc(selectedOpt.offset);
      this.offsetMinutesForModal = Math.abs((selectedOpt.offset - Math.trunc(selectedOpt.offset)) * 60);
    },
    /**
     * Функция конвертации часов и минут в часы в виде десятичной дроби.
     * Например 3 часа 30 минут в 3.5 часа
     *
     * @param {number} hours - часы
     * @param {number} minutes - минуты
     * @returns {number} - часы и минут в виде десятичной дроби
     */
    convertHoursAndMinutesToHours(hours, minutes) {
      const minutesAsHours = minutes / 60 ;
      const result = hours + minutesAsHours
      return parseFloat(result)
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    /**
     * Функция возвращает смешение от UTC  в секундах
     *
     * @param {number} hoursOffset - смещение в часах
     * @param {number} minutesOffset - смещение в минутах
     * @returns {number} - оффсет от UTC  секундах
     */
    getOffsetFromUTCInSecondsFromHoursAndMinutesOffset(hoursOffset, minutesOffset) {
      if (hoursOffset === 0 && minutesOffset === 0) {
        return 0;
      }
      const hours = hoursOffset;
      let result = hours * 60 * 60;
      if (hours > 0) {
        result = result + (minutesOffset * 60)
      } else {
        result = result - (minutesOffset * 60)
      }
      return result
    },
    /**
     * Возвращает смещение бразуера  от UTC в часах и минутах
     *
     * @returns {object} - оффсет от UTC в часах и минутах в виде {hoursOffset: 3, minutesOffset: 0, negativeOffset: true}
     *
     */
    getCurrentBrowserOffserFromUTCInHoursAndMinutes() {
      let result = {hoursOffset: null, minutesOffset: null, negativeOffset: null}
      const offsetInMinutes = (this.currentBrowserTimezoneOffsetInSeconds / 60) * -1;
      // console.log(offsetInMinutes)
      if (offsetInMinutes === 0) {
        result = {hoursOffset: 0, minutesOffset: 0, negativeOffset: false}
      } else if (offsetInMinutes > 0) {

        result = {
          hoursOffset: Math.floor(offsetInMinutes / 60),
          minutesOffset: offsetInMinutes % 60,
          negativeOffset: false
        }
      } else {
        result = {
          hoursOffset: Math.floor((offsetInMinutes / 60) * -1),
          minutesOffset: (offsetInMinutes % 60) * -1,
          negativeOffset: true
        }
      }
      // console.log(result)
      return result;
    },
    /**
     * Пытаемся опеределить имя таймзоны в которй запущен браузер
     *
     * @returns {undefined|string} имя таймзоны в формате Europe/Moscow или undefined
     *
     */
    getCurrentBrowserTimeZoneName() {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return undefined;
      }
    },
    /**
     * Для перерисовки дейтпикера в модалке измененитя даты и времени.
     * Используется когда при  откртом модальном окне происходим прееинициализация выбраной даты
     * Чтобы ее отобразил дейтпикер
     */
    redarawDatePickerInModal() {
      this.showDatePickerInModal = false;
      this.$nextTick(() => {
        this.showDatePickerInModal = true;
      })
    },
    /**
     * Возвращает текущеее системное время как timestamp в секундах
     * @returns {number} timestamp в секундах
     */
    getCurrentBrowserTimeTsSeconds() {
      const currentDate = new Date();
      return Math.floor(currentDate.getTime() / 1000);
    },
    /**
     * С помощью запроса к API устанавливает время и таймзону контроллера
     * значениями как в пользовательском браузере
     */
    syncDateAndTimeWithBrowser() {
      let detectedTimeZone;
      const browserOffsetFromUTC = this.getCurrentBrowserOffserFromUTCInHoursAndMinutes();
      const getCurrentBrowserTimeTsSeconds = this.getCurrentBrowserTimeTsSeconds();
      const offsetHours = browserOffsetFromUTC.negativeOffset ? browserOffsetFromUTC.hoursOffset * -1 : browserOffsetFromUTC.hoursOffset;
      const offsetMinutes = browserOffsetFromUTC.minutesOffset;
      const offsetInHoursForTimezoneFind = parseFloat(this.convertHoursAndMinutesToHours(offsetHours, offsetMinutes) * -1);
      const timeZoneNameForTimeZoneFind = this.getCurrentBrowserTimeZoneName();

      // Если определили имя таймзоны в который запущен браузер то используем ее для поиска объекта таймзоны
      if (timeZoneNameForTimeZoneFind) {
        detectedTimeZone = this.findTimezoneObjectByTimeZoneName(timeZoneNameForTimeZoneFind);
      } else {
        // Если не определили имя таймзоны браузера (браузер не поддерживает или еще что) то ищем объект таймзоны по смещению
        detectedTimeZone = this.findTimezoneObjectByOffsetInHours(offsetInHoursForTimezoneFind);
      }
      const timezoneAcronym = detectedTimeZone ? detectedTimeZone.abbr : 'NONE';
      this.updateContollerTime(getCurrentBrowserTimeTsSeconds, offsetHours, offsetMinutes, timezoneAcronym);
    },
    refreshClickHandler() {
      this.getCurrentContollerTime();
      this.showRefreshClickButton = false;
    },
    clearNTPSchemaData() {
      this.NTPSchemaData = {};
    },
    setDatePickerTsSelectedWithOffsetsForSendToAPI(ts) {
      this.datePickerTsSelectedWithOffsetsForSendToBack = this.getDatePickerTsSelectedWithOffsetsForSendToAPI(ts);
    },
    getDatePickerTsSelectedWithOffsetsForSendToAPI(ts) {
      return ts - this.currentBrowserTimezoneOffsetInSeconds - this.selectedTimezoneInModalOffsetFromUTCInSeconds;
    },
    getCurrentControllerTimeTsFromServerForUseInUI(ts) {
      return ts + this.currentTimezoneOffsetFromUTCInSeconds + this.currentBrowserTimezoneOffsetInSeconds;
    },
    datePickerSelectHandler() {
      this.setDatePickerTsSelectedWithOffsetsForSendToAPI(this.datePickerTsSelected);
    },
    /**
     *  Запускает отсылку выбраных в модалке настроек на бэк
     */
    async modalSaveButtonHandler() {
      if (this.showOverModalSpinner) {
        return;
      }
      const isFormValid = await this.$validator.validateAll();
      if (!isFormValid) {
        return;
      }
      this.updateContollerTime(this.datePickerTsSelectedWithOffsetsForSendToBack, this.offsetHoursForModal, this.offsetMinutesForModal, this.timezoneAcronymForModal);
    },
    syncModalSaveButtonHandler() {
      this.syncDateAndTimeWithBrowser();
    },
    modalCloseButtonHandler() {
      if (this.showOverModalSpinner) {
        return;
      }
      this.closeModal();
    },
    syncModalCloseButtonHandler() {
      this.closeSyncModal();
    },
    /**
     * Очищает поля, инициализируя их дефолтными значениями
     */
    clearCurrentDataFromBackend() {
      this.offsetMinutesFromBackend = 0;
      this.offsetHoursFromBackend = 0;
      this.timezoneAcronymFromBackend = '';
      this.systemTimeUnixFromBackend = undefined;
    },
    /**
     * Инициирует запрос время, смещение и таймзону с контроллера, когда вкладка становится видимой
     */
    visibilityChangeEventHandler() {
      try {
        if (document.visibilityState === 'visible') {
          // console.log('visability equal visible');
          this.closeModal();
          this.getCurrentContollerTime();
        }
      } catch (e) {
        console.log(e);
      }
    },
    syncDateAndTimeClickHandler() {
      this.openSyncModal()
    },
    changeDateAndTimeClickHandler() {
      if (this.showOverAllSpinner) {
        return;
      }
      this.openModal();
    },
    /**
     * Ищет по переданному смещению объект тамзоны в объектах таймзон в timeZonesOptions
     * @param {float} offsetHours смещение в часах (float)
     * @returns объект таймзоны из списка с таймзонами timeZonesOptions
     */
    findTimezoneObjectByOffsetInHours(offsetHours) {
      return this.timeZonesOptions.find((item) => {
        if ((parseFloat(item.offset) + offsetHours) === 0) {
          return true;
        }
      })
    },
    /**
     * Ищет объект таймзоны по переданному названию тамзоны (например Europe/Moscow)
     *
     * @param {string} timezoneName (например Europe/Moscow)
     * @returns объект таймзоны
     */
    findTimezoneObjectByTimeZoneName(timezoneName) {
      return this.timeZonesOptions.find((item) => {
        if (Array.isArray(item.utc) && item.utc.includes(timezoneName)) {
          return true;
        }
      });
    },
    /**
     * Инициализирует поля в модалке, при ее открытии
     */
    initFieldsInModal() {
      this.offsetHoursForModal = this.offsetHoursFromBackend;
      this.offsetMinutesForModal = this.offsetMinutesFromBackend;
      this.timezoneAcronymForModal = this.timezoneAcronymFromBackend;
      this.timezoneSelectedInMultiselect = null;
      this.initDateTimeFieldInModal();
    },
    /**
     * Инициализирует поля для выбора даты/времени в модальном окне
     */
    initDateTimeFieldInModal() {
      this.datePickerIsOpened = false;
      // console.log(Math.floor(Date.now() / 1000));
      if (this.systemTimeUnixFromBackend !== undefined) {
        this.datePickerTsSelected = this.getCurrentControllerTimeTsFromServerForUseInUI(
          this.systemTimeUnixFromBackend
        );
        this.datePickerTsSelectedWithOffsetsForSendToBack = this.getDatePickerTsSelectedWithOffsetsForSendToAPI(
          this.datePickerTsSelected
        );
      } else {
        this.datePickerTsSelected = Math.floor(Date.now() / 1000);
        this.datePickerTsSelectedWithOffsetsForSendToBack = this.getDatePickerTsSelectedWithOffsetsForSendToAPI(
          this.datePickerTsSelected
        );
      }
    },
    openModal() {
      this.$validator.reset();
      this.initFieldsInModal();
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.datePickerTsSelectedWithOffsetsForSendToBack = undefined;
    },
    openSyncModal() {
      this.isSyncModalOpen = true;
    },
    closeSyncModal() {
      this.isSyncModalOpen = false;
    },
    /**
     * Запускает интервалы для инкермента полей с секундами
     *
     */
    startSecondsPlusInterval() {
      this.stopSecondsPlusInterval();
      // this.secondsPlus();
      this.secondsPlusInterval = setInterval(() => {
        this.secondsPlus();
      }, 1000);
    },
    /**
     * Останавливает интервалы для инкермента полей с секундами
     *
     */
    stopSecondsPlusInterval() {
      if (this.secondsPlusInterval) {
        clearInterval(this.secondsPlusInterval);
      }
    },
    /**
     * Инкрементирует поля с таймсттампами
     *
     */
    secondsPlus() {
      if (typeof this.systemTimeUnixFromBackend === 'number') this.systemTimeUnixFromBackend = this.systemTimeUnixFromBackend + 1;
      if (typeof this.datePickerTsSelected === 'number') this.datePickerTsSelected = this.datePickerTsSelected + 1
      if (typeof this.datePickerTsSelectedWithOffsetsForSendToBack === 'number') this.datePickerTsSelectedWithOffsetsForSendToBack = this.datePickerTsSelectedWithOffsetsForSendToBack + 1
      if (!this.datePickerIsOpened) {
        this.redarawDatePickerInModal();
      }
    },
    /**
     * Запрашивает данные о таймзоне, времени и оффсетах на контролллере и
     * заполняет соответсвующие поля в data
     *
     */
    getCurrentContollerTime() {
      this.showRefreshClickButton = false;
      const cancelToken = axios.CancelToken.source();

      const requestName = 'getCurrentContollerTime';
      const componentName = this.$route.name;
      this.$store.commit('clearAndCancelRequestCancelTokenFromRequestsCancelTokensForComponentByTokenName', {
        componentName,
        requestName
      });
      this.$store.commit('addRequestCancelTokenToRequestsCancelTokens', { componentName, requestName, cancelToken });
      this.clearCurrentDataFromBackend();
      this.showGetCurrentContollerTimeSpinner = true;
      Vue.axios
        .get(`${API_URL || ''}/api/ntp_management/ntp_system_time`, { cancelToken: cancelToken.token })
        .then(
          (response) => {
            if (response.data.status === 'success') {
              const currentControllerTimeFromServer = JSON.parse(JSON.stringify(response.data.data.itemslist[0]));
              this.offsetHoursFromBackend = currentControllerTimeFromServer.offset_hour ?? 0;
              this.offsetMinutesFromBackend = currentControllerTimeFromServer.offset_min ?? 0;
              this.timezoneAcronymFromBackend = currentControllerTimeFromServer.cisco_like_tmz ?? '';
              this.systemTimeUnixFromBackend = currentControllerTimeFromServer.system_time_unix ?? undefined;
              this.NTPSchemaData.external_ntp = currentControllerTimeFromServer.external_ntp ?? '';
              // console.log('systemTimeUnixFromBackend', this.systemTimeUnixFromBackend,)
              this.startSecondsPlusInterval();
            } else if (response.data.status === 'error') {
              this.showRefreshClickButton = true;
              VueNotifications.error({ message: response.data.description });
            }
            this.showGetCurrentContollerTimeSpinner = false;
          },
          (err) => {
            if (
              Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
              typeof window.isShowAxiosErrorInConsole === 'function' &&
              window.isShowAxiosErrorInConsole(err)
            ) {
              this.showGetCurrentContollerTimeSpinner = false;
              this.showRefreshClickButton = true;
              window.showAxiosErrInConsole(err);
            } else {
              if (axios.isCancel(err)) {
                console.log(`${requestName} request for ${componentName} is canceled`);
                return;
              } else {
                this.showGetCurrentContollerTimeSpinner = false;
                this.showRefreshClickButton = true;
                VueNotifications.error({ message: err });
              }
            }
          }
        );
    },
    updateContollerTime(ts, offsetHours, offsetMinutes, timezoneAcronym) {
      if (!ts || !timezoneAcronym) {
        return;
      }
      if (offsetHours === null || offsetHours === undefined || offsetMinutes === null || offsetMinutes === undefined) {
        return;
      }
      const updateQuery = {
        action: 'U',
        items: { 0: { date_time: ts, offset: `${offsetHours} ${offsetMinutes}`, cisco_like_tmz: timezoneAcronym} }
      };
      this.showOverModalSpinner = true;
      Vue.axios.post(`${API_URL || ''}/api/ntp_management/date_time`, updateQuery).then(
        (response) => {
          if (response.data.status === 'success') {
            this.closeModal();
            this.closeSyncModal();
            this.getCurrentContollerTime();
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          this.showOverModalSpinner = false;
        },
        (err) => {
          this.showOverModalSpinner = false;
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }
        }
      );
    },
  },
  beforeMount() {
    document.addEventListener('visibilitychange', this.visibilityChangeEventHandler);
  },
  created() {
    this.NTPSchemaData = { ...this.NTPSchemaDataProp };
    this.getTimeZonesFromAPI();
    this.getCurrentContollerTime();
  },
  beforeDestroy() {
    this.stopSecondsPlusInterval();
    document.removeEventListener('visibilitychange', this.visibilityChangeEventHandler);
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    showOverAllSpinner() {
      return this.showGetCurrentContollerTimeSpinner || this.showGetNTPSchemaDataSpinner;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    /**
     * Возвращает сообщение об ошибке валидации для поля оффсета в минутах
     * * @returns {string}
     */
    errorTextForOffsetMinutesField() {
      if (this.errors.first('offsetMinutes')) {
        return `${this.errors.first('offsetMinutes')} (${this.$t('controllerDateTimeSettings.offsetMinutesHint')})`;
      }
      return undefined;
    },
    /**
     * Возвращает список таймзон для селектора
     * * @returns {array}
     */
    timeZonesOptions() {
      // опиции для выбора timezones
      return this.timeZonesList;
    },
    /**
     * Возвращает порядок отображения дней недели
     * * @returns {array}
     */
    daysOfWeekOrder() {
      return DAYS_OF_WEEK_ORDER;
    },
    /**
     * Возвращает список дней недели в зависимости от выбраной локали
     * * @returns {array}
     */
    daysOfWeek() {
      if (this.isRuLocale) {
        return DAYS_OF_WEEK.ru;
      }

      return DAYS_OF_WEEK.en;
    },
    /**
     * Возвращает номер для недели выбраной на контроллере даты, используется для  вывода в UI информации
     * * @returns {number|undefined}
     */
    currentDayOfWeekNumber() {
      if (this.systemTimeUnixFromBackend !== undefined) {
        const date = new Date(this.currentControllerTimeTsFromServerForUseInUI * 1000);
        const dayOfWeek = date.getDay();
        return dayOfWeek;
      } else {
        return undefined;
      }
    },
    /**
     * Возвращает строку для вывода в UI информации о выбраной на контроллере дате
     * @returns {string|undefined}
     */
    currentDateForShowInUI() {
      if (this.systemTimeUnixFromBackend !== undefined) {
        return moment.unix(this.currentControllerTimeTsFromServerForUseInUI).format('D MMM YYYY');
      } else {
        return undefined;
      }
    },
    /**
     * Возвращает строку для вывода в UI информации о выбраном на контроллере смещении и акрониме таймзоны
     * @returns {string}
     */
    currentTimezoneForShowInUI() {
      const minutesForShow = '0' + this.offsetMinutesFromBackend;
      const hoursForShow = this.offsetHoursFromBackend >= 0 ? `+${this.offsetHoursFromBackend}` : this.offsetHoursFromBackend
      return `UTC${hoursForShow}:${minutesForShow.substr(-2)} ${this.timezoneAcronymFromBackend ?? ''}`
    },
    /**
     * Возвращает смещение от UTC в секунадх для таймзоны браузера юзера
     * @returns {number}
     */
    currentBrowserTimezoneOffsetInSeconds() {
      return new Date().getTimezoneOffset() * 60;
    },
    /**
     * Возвращает смещение от UTC в секунадх для установленого на контроллере смещения в часах и минутах
     * @returns {number}
     */
    currentTimezoneOffsetFromUTCInSeconds() {
      const offsetHours = this.offsetHoursFromBackend;
      const offsetMinutes = this.offsetMinutesFromBackend;
      const result = this.getOffsetFromUTCInSecondsFromHoursAndMinutesOffset(offsetHours, offsetMinutes);
      return result;
    },
    /**
     * Возвращает смещение от UTC в секунадх для выбраных в модалке смещения в часах и минутах
     * @returns {number}
     */
    selectedTimezoneInModalOffsetFromUTCInSeconds() {
      const offsetHours = this.offsetHoursForModal;
      const offsetMinutes = this.offsetMinutesForModal;
      const result = this.getOffsetFromUTCInSecondsFromHoursAndMinutesOffset(offsetHours, offsetMinutes);
      // console.log('result', result);
      return result;
    },
    /**
     * Возвращает Возвращает строку (или undefined) со временем для отображения в интерфейсе
     * @returns {string|undefined}
     */
    currentTimeForDisplayInUI() {
      if (this.systemTimeUnixFromBackend !== undefined) {
        const date = new Date(this.currentControllerTimeTsFromServerForUseInUI * 1000);
        const hours = '0' + date.getHours();
        const minutes = '0' + date.getMinutes();
        const seconds = '0' + date.getSeconds();
        const formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime;
      } else {
        return undefined;
      }
    },
    /**
     * Возвращает строку (или undefined) с источником - локальный или местный
     * @returns {string|undefined}
     */
    externalOrNotNTPForDisplayInUI() {
      if (
        typeof this.NTPSchemaData === 'object' &&
        this.NTPSchemaData !== null &&
        Object.prototype.hasOwnProperty.call(this.NTPSchemaData, 'external_ntp')
      ) {
        if (this.NTPSchemaData.external_ntp === 'false') {
          return 'Local';
        }
        if (this.NTPSchemaData.external_ntp === 'true') {
          return 'NTP';
        }
        return undefined;
      }
      return undefined;
    },
    currentControllerTimeTsFromServerForUseInUI() {
      return this.getCurrentControllerTimeTsFromServerForUseInUI(this.systemTimeUnixFromBackend);

    }
  }
};
</script>

<style lang="scss" scoped>
  .timezoneAcronym-wrapper {
    width: 120px;
  }

  .time-showing-wrapper {
    font-size: 3em;
    font-weight: bold;
  }

  .day-of-week-wrapper {
    .active-day-of-week {
      background-color: #00aced;
      color: white;
    }

    .day-of-week {
      width: 3em;
      height: 2em;
      line-height: 1.8;
    }
  }

  .time-and-day-of-week-wrapper {
    border: 0.5px dashed lightgray;
    border-radius: 10px;
    padding: 1em;
  }

  .time-zone-name {
    max-width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .time-zone-multiselect-wrapper {
    position: relative;
    top: 1.1rem;
  }
</style>
